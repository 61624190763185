import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Header from "../components/header"

interface IState {
  faqs: Array<any>
}

export default class Faq extends React.Component<any, IState> {
  constructor(props) {
    super(props)
    this.state = {
      faqs: [
        {
          question: "How long does shipping take?",
          answer: (
            <>
              <p>
                Now that’s a frequent one all right, ain’t it? Your answer to this should{" "}
                <em>manage customer expectations</em>. Just like{" "}
                <a href="http://bit.ly/2YCN3iG">a good checkout flow</a> should. Tell them the
                truth:
              </p>
              <ul>
                <li>US shipping will take between 3 to 5 business days.</li>
                <li>International shipping will take between 5 and 10 business days.</li>
                <li>
                  Mars shipping will be automatically redirected to Elon Musk’s Twitter account.
                </li>
              </ul>
            </>
          ),
        },
        {
          question: "How do refunds and returns work?",
          answer: (
            <>
              <p>
                It happens. Even the most beautiful of plants will disappoint <em>someone</em>.
                Again, tell it like it is:
              </p>
              <ul>
                <li>All discounted items sales are final.</li>
                <li>
                  If you’d like another version of your item, please use the return label.
                  Instructions are printed on its back.
                </li>
                <li>
                  If your item is damaged or not functioning correctly, email us at{" "}
                  <a href="mailto:info@planty.com">info@planty.com</a>, and we’ll refund you + send
                  you a new one ASAP!
                </li>
              </ul>
            </>
          ),
        },
        {
          question: "How do you handle sensitive customer information?",
          answer: (
            <>
              <p>
                <a href="https://media3.giphy.com/media/1FMaabePDEfgk/giphy.gif?cid=790b76115d1fc3ed7656643632f4131f&amp;rid=giphy.gif">
                  GDPR
                </a>
                , am I right? Make sure you’re as transparent as possible with your data handling
                process. Or, if you’re using Snipcart, just refer to{" "}
                <a href="http://bit.ly/2YJwlyt">our ToS and DPA</a>.
              </p>
            </>
          ),
        },
        {
          question: "Rapid-fire questions",
          answer: (
            <p>
              Q: Why is this Planty theme so beautiful? <br /> A: We hired our first designer a
              couple of months ago. <br />
              <br /> Q: Why did you build a theme for Stackbit? <br /> A: We believe in Stackbit’s
              promise of opening up the JAMstack to more developers. Plus, we trust these folks.{" "}
              <a href="http://bit.ly/2YAvGix">Read more about Stackbit</a>. <br />
              <br /> Q: How many people work at Snipcart? <br /> A: Not a lot! We’re a small,
              bootstrapped team of eight human beings. Michael might be a robot, though. <br />
              <br /> Q: Why is Snipcart based in Québec City? <br /> A: Have you been here? It’s
              beaaautiful! <br />
              <br /> Q: What’s the meaning of life? <br /> A: We’re not quite sure. But building
              useful products with people we love feels meaningful enough.
            </p>
          ),
        },
      ],
    }
  }

  renderFaq(item, index) {
    return (
      <section key={index} className="content__row faq__section">
        <div className="faq__section-content-container">
          <h2 className="faq__question">{item.question}</h2>
          <div className="faq__answer">{item.answer}</div>
        </div>
      </section>
    )
  }

  render() {
    return (
      <Layout>
        <Header />
        {/* {this.renderContent()} */}
      </Layout>
    )
  }

  renderContent() {
    const { faqs } = this.state
    return (
      <main className="content faq">
        <section className="content__row content__row--bleed static-header__header">
          <h1 className="static-header__title content__row">Behold our FAQ</h1>
          <div className="content__row static-header__content">
            <p>
              All right, friends. Let’s do this. This section will cover basic, frequently asked
              questions for e-commerce merchants.
            </p>
          </div>
        </section>

        {faqs.map(this.renderFaq)}

        <section className="content__row content__row--bleed faq__contact">
          {/* No need for supravore, for now. Use this section for comments, newsletter subscription... */}
          {/* <img src="\images\promo.jpg" alt="" className="faq__contact-image" />
            <div className="faq__contact-text-container">
              <h3 className="faq__contact-title">
                Still have questions? <br /> Contact us.
              </h3>
              <div className="faq__contact-info-container">
                <p className="faq__contact-address">
                  226 rue St-Joseph E, Québec, QC, Canada, G1K 3A9
                </p>
                <p className="faq__contact-telephone">+1-202-555-0112</p>
                <p className="faq__contact-email">geeks@snipcart.com</p>
                <div className="faq__seperator" />
                <a
                  className="faq__map-link link link--filled link--reversed"
                  href="https://goo.gl/maps/g6za62bN9BHrNTVt5"
                >
                  On the map
                  <svg
                    width="17"
                    height="24"
                    viewBox="0 0 17 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.25031 0C3.67589 0 0 3.67445 0 8.25031C0 12.8247 8.25031 24 8.25031 24C8.25031 24 16.5006 12.8247 16.5006 8.25031C16.5006 3.67445 12.8247 0 8.25031 0ZM8.25031 11.9993C6.15084 11.9993 4.49991 10.3498 4.49991 8.24888C4.49991 6.1494 6.1494 4.49847 8.25031 4.49847C10.3498 4.49847 12.0007 6.14796 12.0007 8.24888C12.0007 10.3498 10.3498 11.9993 8.25031 11.9993Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </div>
            </div> */}
        </section>
      </main>
    )
  }
}
